export const getFontStyles = (pathLink: string | null | undefined) => {
  if (!pathLink) {
    throw new Error('Path link is not found')
  }

  return `
  @font-face {
    font-family: 'Brown';
    src: url('${pathLink}/v2/assets/fonts/lineto-brown-light.ttf')
      format('truetype');
    font-weight: 300;
  }

  @font-face {
    font-family: 'Brown';
    src: url('${pathLink}/v2/assets/fonts/lineto-brown-regular.ttf')
      format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Brown';
    src: url('${pathLink}/v2/assets/fonts/lineto-brown-bold.ttf')
      format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('${pathLink}/v2/assets/fonts/gilroy-medium.ttf')
      format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Gilroy';
    src: url('${pathLink}/v2/assets/fonts/gilroy-bold.ttf') format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Guttaperka';
    src: url('${pathLink}/v2/assets/fonts/guttaperka-semibold.ttf')
      format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Guttaperka';
    src: url('${pathLink}/v2/assets/fonts/guttaperka-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Satoshi';
    src: url('${pathLink}/v2/assets/fonts/satoshi-medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Satoshi';
    src: url('${pathLink}/v2/assets/fonts/satoshi-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Satoshi';
    src: url('${pathLink}/v2/assets/fonts/satoshi-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Satoshi';
    src: url('${pathLink}/v2/assets/fonts/satoshi-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Playfair Display';
    src: url('${pathLink}/v2/assets/fonts/PlayfairDisplay.ttf')
      format('truetype');
  }
`
}
